/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import 'react-multi-carousel/lib/styles.css'

let intervalo = setInterval(() => {
	let carouselBtnLeft = document.querySelector('.react-multiple-carousel__arrow--left')
	let carouselBtnRight = document.querySelector('.react-multiple-carousel__arrow--right')

	if (carouselBtnLeft && carouselBtnRight) {
		clearInterval(intervalo)
		carouselBtnLeft.addEventListener('click', () => {
			let prevImage = document
				.querySelector('.react-multi-carousel-item--active')
				.previousSibling.querySelector('.img-carousel img').src

			document.querySelector('.react-multi-carousel-list').style.backgroundImage = 'url(' + prevImage + ')'
		})
		carouselBtnRight.addEventListener('click', () => {
			let nextImage = document
				.querySelector('.react-multi-carousel-item--active')
				.nextSibling.querySelector('.img-carousel img').src

			document.querySelector('.react-multi-carousel-list').style.backgroundImage = 'url(' + nextImage + ')'
		})
	}
}, 100)
